import { IconBox, IconProps } from '@summer/jst-react-icon';

export const Muted = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2402 1V62.3746L16.6641 44.8155H0V18.5592H16.6641L39.2402 1ZM34.6237 10.4391L18.2481 23.1757H4.61649V40.199H18.2481L34.6237 52.9356V10.4391Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5081 31.687L64 25.195L60.7357 21.9307L54.2437 28.4226L47.7518 21.9307L44.4874 25.195L50.9794 31.687L44.4874 38.1789L47.7518 41.4433L54.2437 34.9513L60.7357 41.4433L64 38.1789L57.5081 31.687Z"
    />
  </IconBox>
);
