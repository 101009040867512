import { Events, Events_Filter } from 'src/graphql/schema.graphql';
import { eventsGqlFragment } from 'src/modules/events/graphql/events.fragment';
import { videosBasicFragment } from 'src/modules/videos/graphql/videos-basic.fragment';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const eventDetailsGqlQuery: GqlFragment<
  { events: Events[] },
  { filters: Events_Filter; sort: string[] }
> = gql`
  ${eventsGqlFragment}
  ${videosBasicFragment}
  query EventDetails($filters: events_filter, $sort: [String]) {
    events(filter: $filters, sort: $sort, limit: -1) {
      ...events
      seoImage {
        id
      }
      videos {
        videos_id {
          ...videosBasic
        }
      }
      speakers {
        people_id {
          name
          portrait {
            id
          }
          translations {
            title
            degree
            description
            languages_code {
              code
            }
          }
        }
      }
      subEvents {
        id
      }
      faq(sort: ["sort"]) {
        eventFaq_id {
          translations {
            answer
            question
            languages_code {
              code
            }
          }
        }
      }
    }
  }
`;
