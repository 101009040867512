import { useClientReady } from '@summer/jst-react';
import { useRouter } from 'next/router';
import { useEventDetailsQuery } from 'src/modules/events/hooks/useEventDetailsQuery';
import { useEffect } from 'react';
import {
  eventPageRoute,
  Route,
  route,
  videoPageRoutes,
} from 'src/services/route';
import { isTimeNow } from 'src/hooks/useTimeState';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/store/actions';
import { PlayerId } from 'src/components/player/playerId';
import { sessionStorage } from 'src/utils/storage';

export const hasMiniplayerBeenClosed = () =>
  !!sessionStorage.getItem('nc_tv-miniplayer-closed');

export const useMiniplayerAutoOpen = () => {
  const router = useRouter();
  const clientReady = useClientReady();
  const dispatch = useDispatch();

  const eventQuery = useEventDetailsQuery(
    (router.query.slug ?? '') as string,
    false,
  );

  useEffect(() => {
    if (!clientReady) {
      return;
    }

    const autoOpen = async () => {
      const disallowedInitialRoutes = [
        route.homepage(),
        ...videoPageRoutes,
        Route.EventsEpisodesPage,
        Route.EventsEpisodesItemPage,
        Route.EventsDetailsPage,
      ];

      if (eventPageRoute === router.route) {
        const { data: event } = await eventQuery.refetch();

        if (event && event.videos[0] && isTimeNow(event)) {
          disallowedInitialRoutes.push(eventPageRoute);
        }
      }

      if (
        clientReady &&
        !disallowedInitialRoutes.includes(router.route) &&
        !hasMiniplayerBeenClosed()
      ) {
        dispatch(
          AppAction.openMiniplayer({
            playerId: PlayerId.TVLike,
          }),
        );
      }
    };

    autoOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientReady]);
};
