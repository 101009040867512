import { memo } from 'react';
import styled from 'styled-components';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { lineClamp } from 'src/styles/common';
import { CleanButton } from 'src/components/common/Button';
import { media } from 'src/styles/designs';
import { Unmuted } from 'src/components/icons/Unmuted';
import { Muted } from 'src/components/icons/Muted';
import { Close } from 'src/components/icons/Close';
import { Videos } from 'src/graphql/schema.graphql';
import { useUserHealthcareLegalConsent } from 'src/hooks/useUserHealthcareLegalConsent';
import { Theme } from 'vendor/mods/styled-components';
import { redTheme } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { PlayerId } from 'src/components/player/playerId';
import { MiniplayerHeader } from 'src/components/common/Layout/Miniplayer/MiniplayerHeader';

const Wrapper = styled.aside`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.text.palette.primary};
  ${media.w.lessThan.desktop} {
    padding-right: 24px;
    height: 70px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  ${media.w.greaterThanOrEqual.desktop} {
    align-items: flex-start;
    min-height: 66px;
    padding: 10px;
  }
`;

const VideoTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  ${lineClamp(2)};
  ${media.w.lessThan.desktop} {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    flex: 1;
  }
`;

const ConsentText = styled(VideoTitle)`
  text-decoration: underline;
  ${media.w.lessThan.desktop} {
    font-size: 10px;
    padding: 0 0 0 10px;
  }
`;

const ControlButton = styled(CleanButton)`
  margin-left: 10px;
  padding: 5px;
  color: ${({ theme }) => theme.text.palette.primary};
  ${media.w.greaterThanOrEqual.desktop} {
    display: none;
  }
`;

const StyledMiniplayerHeader = styled(MiniplayerHeader)`
  padding: 0;

  ${media.w.greaterThanOrEqual.desktop} {
    display: none;
  }
`;

export interface MobileControlsProps {
  video?: Videos;
  isMuted: boolean;
  playerId: PlayerId | undefined;
  onReturn: () => void;
  onClose: () => void;
  toggleMute: () => void;
}

// eslint-disable-next-line react/display-name
export const MiniplayerPlayerControls = memo<MobileControlsProps>(
  ({ video, isMuted, playerId, onReturn, toggleMute, onClose }) => {
    const { _ } = useTranslate();
    const translateItem = useTranslateItem();

    const translatedVideo = video ? translateItem(video) : null;

    const isDesktop = useMediaQuery(media.w.greaterThanOrEqual.desktop);
    const { userConsentValue } = useUserHealthcareLegalConsent();

    return (
      <Theme theme={redTheme}>
        <Wrapper>
          {userConsentValue || isDesktop ? (
            <Container>
              <VideoTitle onClick={onReturn}>
                <StyledMiniplayerHeader playerId={playerId} />
                {translatedVideo?.name}
              </VideoTitle>
              <ControlButton onClick={toggleMute}>
                {isMuted ? <Muted size={23} /> : <Unmuted size={23} />}
              </ControlButton>
            </Container>
          ) : (
            <ConsentText onClick={onReturn}>
              {_('MEDICAL CONTENT CONFIRMATION OF PROFESSION REQUIRED')}
            </ConsentText>
          )}
          <ControlButton onClick={onClose}>
            <Close size={20} />
          </ControlButton>
        </Wrapper>
      </Theme>
    );
  },
);
