import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { gql, GqlFragment } from 'src/utils/gql/query';
import { APIs } from 'src/models/apis';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { TvScheduleConfiguration } from 'src/graphql/schema.graphql';
import { queryCommonOptions } from 'src/utils/queryCommonOptions';
import { useAPIs } from 'src/hooks/useAPIs';
import { shortcodesGqlFragment } from 'src/modules/videos/graphql/videos-shortcodes.fragment';

const tvScheduleGqlQuery: GqlFragment<{
  tvScheduleConfiguration: TvScheduleConfiguration;
}> = gql`
  ${shortcodesGqlFragment}

  fragment video on videos {
    id
    slug
    thumbnail {
      id
    }
    speakers {
      people_id {
        id
        avatar {
          id
        }
        name
        translations {
          degree
          title
          languages_code {
            code
          }
        }
        country {
          translations {
            languages_code {
              code
            }
            name
          }
        }
      }
    }

    shortcodes {
      ...shortcodes
    }
    tags {
      tags_id {
        id
        translations {
          name
          languages_code {
            code
          }
        }
      }
    }
    disclaimer
    source {
      id
      url
      format
      duration
      startDate
      endDate
    }
    translations {
      description
      excerpt
      name
      languages_code {
        code
      }
    }
  }

  query {
    tvScheduleConfiguration {
      prelude {
        ...video
      }
      platformTrailer {
        ...video
      }
    }
  }
`;

export const tvScheduleConfigurationQuery = (
  apis: APIs,
): UseQueryOptions<TvScheduleConfiguration> => {
  return {
    queryKey: QueryKey.TVScheduleConfiguration,
    ...queryCommonOptions.commonHandlers,
    ...queryCommonOptions.longStale,
    queryFn: async () => {
      const directus = await apis.directus();

      return directus
        .query(tvScheduleGqlQuery)
        .then((a) => a.tvScheduleConfiguration);
    },
  };
};

export const useTVScheduleConfigurationQuery =
  (): UseQueryResult<TvScheduleConfiguration> => {
    return useQuery(tvScheduleConfigurationQuery(useAPIs()));
  };
