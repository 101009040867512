import { memo } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-t7e';
import { PlayerId } from 'src/components/player/playerId';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { StyleProps } from '@summer/jst-react';
import { redTheme } from 'src/styles/theme';
import { Theme } from 'vendor/mods/styled-components';

const Header = styled.div`
  padding: 5px 9px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.text.palette.primary};

  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;

export interface MiniplayerHeaderProps extends StyleProps {
  playerId: PlayerId | undefined;
}

export const MiniplayerHeader = memo<MiniplayerHeaderProps>(
  ({ playerId, ...props }) => {
    const { _ } = useTranslate();

    return (
      <Theme theme={redTheme}>
        {playerId?.includes(PlayerId.TVLike) && (
          <Header {...props}>
            <Markdown>{_('NEAUVIA TV')}</Markdown>
          </Header>
        )}
      </Theme>
    );
  },
);

MiniplayerHeader.displayName = 'MiniplayerHeader';
