import styled from 'styled-components';
import { useTVScheduleQuery } from 'src/services/tvSchedule';
import { useTVScheduleConfigurationQuery } from 'src/services/tvScheduleConfiguration';
import { withQueries } from 'src/utils/reactQuery';
import { AnimatePresence, motion } from 'framer-motion';
import { makeTransition } from 'src/utils/motion/transitions';
import { useCallback, useEffect, useRef } from 'react';
import { media } from 'src/styles/designs';
import { MiniplayerPlayer } from 'src/components/common/Layout/Miniplayer/MiniplayerPlayer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMiniplayerCurrent,
  selectMiniplayerOnReturn,
} from 'src/store/miniplayer/selectors';
import { AppAction } from 'src/store/actions';
import { NeauviaPlayerAPI } from 'src/components/player';
import { PlayerId } from 'src/components/player/playerId';
import { useRouter } from 'next/router';
import { route } from 'src/services/route';
import { MiniplayerTVPlayer } from 'src/components/common/Layout/Miniplayer/MiniplayerTVPlayer';
import { useMiniplayerAutoOpen } from 'src/components/common/Layout/Miniplayer/useMiniplayerAutoOpen';
import { dirRTL } from 'src/styles/theme';

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: calc(var(--footer-fixed-height) + 20rpx);
  right: 20rpx;
  width: 375px;
  z-index: 49;
  background: #ffffff;
  box-shadow: 0 3px 6px #4b4b4b28;
  transition: bottom 0.25s;
  ${dirRTL} {
    right: auto;
    left: 20rpx;
  }

  ${media.w.lessThan.desktop} {
    width: 100%;
    max-width: 100vw;
    left: 0 !important;
    right: 0 !important;
    bottom: var(--footer-fixed-height);
    box-shadow: 0px -1px 6px #4b4b4b28;
  }

  ${media.w.greaterThanOrEqual.desktop} {
    .player__consent-block {
      gap: 15px;
      width: calc(100% - 60px);
      & > div {
        font-size: 16px;
      }
      & > button {
        min-height: 40px;
      }
    }
  }
`;

const transition = makeTransition({
  from: 'bottom',
  to: 'bottom',
  fade: true,
  enterDelay: 0.2,
});

export const miniplayerController: {
  close?: () => void;
  return?: () => void;
} = {};

const Miniplayer = withQueries(
  {
    schedule: useTVScheduleQuery,
    scheduleConfiguration: useTVScheduleConfigurationQuery,
  },
  () => {
    const player = useRef<NeauviaPlayerAPI | null>(null);
    const router = useRouter();
    const dispatch = useDispatch();
    const currentMiniplayer = useSelector(selectMiniplayerCurrent);
    const currentOnReturn = useSelector(selectMiniplayerOnReturn);
    const isTVLike = currentMiniplayer?.playerId.includes(PlayerId.TVLike);

    const onReturn = useCallback(() => {
      if (currentMiniplayer == null) {
        return;
      }

      const playerState = player.current?.stateSnapshot;
      const state = {
        playing: playerState?.playing,
        playFrom: playerState?.time,
        muted: playerState?.muted,
        volume: playerState?.volume,
      };

      if (currentOnReturn != null) {
        dispatch(AppAction.resetMiniplayer({}));
        setTimeout(() => currentOnReturn(state), 0);
      } else {
        dispatch(
          AppAction.snapshotMiniplayer({
            ...state,
            playerId: currentMiniplayer.playerId,
          }),
        );
        if (
          currentMiniplayer.playerId.includes(PlayerId.Common) &&
          currentMiniplayer.video != null
        ) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          router.replace(route.video(currentMiniplayer.video).page());
        } else if (isTVLike) {
          router.replace(route.homepage());
        }
      }
    }, [router, dispatch, currentMiniplayer, isTVLike, currentOnReturn]);

    const onClose = useCallback(
      () => dispatch(AppAction.resetMiniplayer({})),
      [dispatch],
    );

    useEffect(() => {
      miniplayerController.return = () => onReturn();
      miniplayerController.close = () => onClose();

      return () => {
        delete miniplayerController.return;
        delete miniplayerController.close;
      };
    }, [onReturn, onClose]);

    useMiniplayerAutoOpen();

    return (
      <AnimatePresence>
        {currentMiniplayer && !isTVLike && (
          <Wrapper {...transition}>
            <MiniplayerPlayer
              ref={player}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              video={currentMiniplayer.video!}
              onReturn={onReturn}
              onClose={onClose}
            />
          </Wrapper>
        )}
        {currentMiniplayer && isTVLike && (
          <Wrapper {...transition}>
            <MiniplayerTVPlayer
              ref={player}
              onReturn={onReturn}
              onClose={onClose}
            />
          </Wrapper>
        )}
      </AnimatePresence>
    );
  },
);

export default Miniplayer;
