import { currentTime$ } from 'src/utils/time';
import { EvolvedTvSchedule, getLiveTimeOffset } from 'src/services/tvSchedule';
import { Videos } from 'src/graphql/schema.graphql';
import { MutableRefObject, useCallback } from 'react';
import { NeauviaPlayerAPI } from 'src/components/player';
import { isLiveStream } from 'src/modules/videos/utils/isLiveStream';

export type ScheduleItemWithOffset = {
  schedule: EvolvedTvSchedule | null;
  video: Videos;
  playFrom?: number;
};

export const useLiveOnPlayCallback = (
  currentVideo: ScheduleItemWithOffset | undefined,
  playerApi: MutableRefObject<NeauviaPlayerAPI | null>,
) =>
  useCallback(async () => {
    if (currentVideo?.schedule != null && playerApi.current != null) {
      if (isLiveStream(currentVideo.video)) {
        setTimeout(async () => {
          playerApi.current?.seekTo(Number.MAX_SAFE_INTEGER);
        }, 0);
      } else {
        const time = await currentTime$.take(1).toPromise();
        const playingFrom = getLiveTimeOffset(time, currentVideo.schedule);
        playerApi.current.seekTo(playingFrom);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideo]);
