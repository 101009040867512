import { APIs } from 'src/models/apis';
import { useQuery } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { QueryKey } from 'src/models/query';
import { eventDetailsGqlQuery } from 'src/modules/events/graphql/events-details.query';

export const fetchEventDetails = async (apis: APIs, slug: string) => {
  const directus = await apis.directus();

  return directus
    .query(eventDetailsGqlQuery, {
      filters: {
        slug: { _eq: slug },
        status: { _eq: 'published' },
      },
    })
    .then((a) => a.events[0]);
};

export const useEventDetailsQuery = (slug: string, enabled?: boolean) => {
  const apis = useAPIs();

  return useQuery(
    [...QueryKey.EventPage, slug],
    () => fetchEventDetails(apis, slug),
    {
      enabled,
    },
  );
};
