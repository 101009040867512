import React, { forwardRef, memo, useCallback, useRef } from 'react';
import { MiniplayerPlayer } from 'src/components/common/Layout/Miniplayer/MiniplayerPlayer';
import { NeauviaPlayerAPI } from 'src/components/player';
import useMergedRef from '@react-hook/merged-ref';
import {
  useCurrentTVSectionItem,
  useTVScheduleQuery,
} from 'src/services/tvSchedule';
import { useTVScheduleConfigurationQuery } from 'src/services/tvScheduleConfiguration';
import { useLiveOnPlayCallback } from 'src/modules/home/components/HomeTVSection/common';
import { PlayerId } from 'src/components/player/playerId';
import { sessionStorage } from 'src/utils/storage';

const wistiaOptions: WistiaPlayer.PlayerOptions = {
  playbar: false,
  playSuspendedOffScreen: false,
  smallPlayButton: false,
  playButton: false,
};

export interface MiniplayerTvPlayerProps {
  onReturn: () => void;
  onClose: () => void;
}

// eslint-disable-next-line react/display-name
export const MiniplayerTVPlayer = memo(
  forwardRef<NeauviaPlayerAPI, MiniplayerTvPlayerProps>(
    ({ onReturn, onClose }, ref) => {
      const player = useRef<NeauviaPlayerAPI | null>(null);
      const mergedRef = useMergedRef(player, ref);

      const { data: schedule } = useTVScheduleQuery();
      const { data: scheduleConfiguration } = useTVScheduleConfigurationQuery();
      const { currentVideo } = useCurrentTVSectionItem(
        schedule,
        scheduleConfiguration,
      );
      const liveOnPlayCallback = useLiveOnPlayCallback(currentVideo, player);

      const handleClose = useCallback(() => {
        sessionStorage.setItem('nc_tv-miniplayer-closed', 'true');
        onClose();
      }, [onClose]);

      if (currentVideo == null) {
        return null;
      }

      return (
        <MiniplayerPlayer
          ref={mergedRef}
          playerId={PlayerId.TVLike}
          video={currentVideo.video}
          playFrom={currentVideo.playFrom}
          onReturn={onReturn}
          onClose={handleClose}
          loop
          wistiaOptions={wistiaOptions}
          onPlay={liveOnPlayCallback}
        />
      );
    },
  ),
);
