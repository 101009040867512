import { IconBox, IconProps } from '@summer/jst-react-icon';

export const Unmuted = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.9188 0V64L18.377 45.6897H1V18.3103H18.377L41.9188 0ZM37.1048 9.84283L20.0287 23.1242H5.81397V40.8758H20.0287L37.1048 54.1572V9.84283Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.1928 22.8228V41.176H48.3788V22.8228H53.1928Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.2189 18.3096V45.689H57.4049V18.3096H62.2189Z"
    />
  </IconBox>
);
