import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { media } from 'src/styles/designs';
import {
  NeauviaMiniplayerPlayer,
  NeauviaPlayerAPI,
  NeauviaPlayerControls,
  NeauviaPlayerStateSnapshot,
} from 'src/components/player';
import { Videos } from 'src/graphql/schema.graphql';
import useMergedRef from '@react-hook/merged-ref';
import styled from 'styled-components';
import { MiniplayerPlayerControls } from 'src/components/common/Layout/Miniplayer/MiniplayerPlayerControls';
import { PlayerId } from 'src/components/player/playerId';
import { MiniplayerHeader } from 'src/components/common/Layout/Miniplayer/MiniplayerHeader';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.text.palette.primary};

  ${media.w.lessThan.desktop} {
    display: flex;

    .neauvia-player {
      width: 192px;
      height: 70px;
      padding: 0;
    }
  }

  ${media.w.greaterThanOrEqual.desktop} {
    .player__mute-overlay {
      --base-unit: min(100vw, 1300rpx) / 1366;
    }
  }
`;

const StyledMiniplayerHeader = styled(MiniplayerHeader)`
  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

export interface MiniplayerPlayerProps {
  playerId?: PlayerId;
  video: Videos;
  playFrom?: number;
  loop?: boolean;
  wistiaOptions?: WistiaPlayer.PlayerOptions;
  onReturn: () => void;
  onClose: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
}

// eslint-disable-next-line react/display-name
export const MiniplayerPlayer = memo(
  forwardRef<NeauviaPlayerAPI, MiniplayerPlayerProps>(
    (
      {
        playerId,
        video,
        playFrom,
        wistiaOptions,
        loop,
        onReturn,
        onClose,
        onPlay,
        onPause,
        onEnded,
      },
      ref,
    ) => {
      const player = useRef<NeauviaPlayerAPI | null>(null);
      const mergedPlayerRef = useMergedRef(ref, player);
      const isMobile = useMediaQuery(media.w.lessThan.desktop);
      const [isMuted, setIsMuted] = useState<boolean>(true);

      const playerControls: NeauviaPlayerControls = useMemo(
        () => ({
          native: {
            enabled: !isMobile,
          },
          shortcodes: {
            enabled: false,
          },
          relatedVideos: {
            enabled: false,
          },
          userActions: {
            enabled: false,
          },
          miniplayerActions: {
            enabled: true,
            onReturn,
            onClose,
          },
          healthcareConsent: {
            enabled: true,
            backgroundOnly: isMobile,
            onBackgroundClick: isMobile ? onReturn : undefined,
          },
          mdrDisclaimer: {
            enabled: !isMobile,
          },
        }),
        [isMobile, onReturn, onClose],
      );

      const onReady = useCallback(
        (state: NeauviaPlayerStateSnapshot) => setIsMuted(state.muted),
        [setIsMuted],
      );

      const onMute = useCallback(() => setIsMuted(true), [setIsMuted]);

      const onUnmute = useCallback(() => setIsMuted(false), [setIsMuted]);

      const toggleMute = useCallback(
        () => (isMuted ? player.current?.unmute() : player.current?.mute()),
        [isMuted],
      );

      return (
        <Container>
          <StyledMiniplayerHeader playerId={playerId} />
          <NeauviaMiniplayerPlayer
            ref={mergedPlayerRef}
            ar={16 / 9}
            playerId={playerId}
            video={video}
            playFrom={playFrom}
            autoPlay
            loop={loop}
            controls={playerControls}
            onReady={onReady}
            onMute={onMute}
            onUnmute={onUnmute}
            wistiaOptions={wistiaOptions}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
          />
          <MiniplayerPlayerControls
            video={video}
            playerId={playerId}
            isMuted={isMuted}
            onReturn={onReturn}
            onClose={onClose}
            toggleMute={toggleMute}
          />
        </Container>
      );
    },
  ),
);
